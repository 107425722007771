import { redirect } from "@sveltejs/kit";
import { browser } from "$app/environment";
import { globalIntents, isIntent, redirectIntents, resolveIntentRedirect } from "$lib/intents";
import { confirm } from "$lib/subscription-service";
import { loadUser, propagateSafeParams, unloadUser } from "$lib/utils";
import type { PageLoad } from "./$types";
import { purchaseIfNecessary } from "$lib/payments-service";
import { base } from "$app/paths";

export const load: PageLoad = async ({ parent, url }) => {
  if (!browser) return;

  /**
   * This query param should only be present in conjunction
   * with ?previous_action=change-email, and represents the
   * user's new email address.
   */
  const email = decodeURIComponent(url.searchParams.get("email"));

  let { intent, productId, productName, user, requestId, code } = await parent();

  /**
   * Confirm auth/log in from email
   */
  if (!user && requestId && code) {
    try {
      user = await confirm(requestId, code);
      loadUser(user);
    } catch (e) {
      console.error(e);
      unloadUser();
      redirect(307, propagateSafeParams(url, base || "/"));
    }
  }

  /**
   * Handle logged out cases
   */
  // If there's no user, but there's an intent with an invalid value, strip query params
  if (!user && intent && !isIntent(intent, globalIntents)) {
    redirect(307, propagateSafeParams(url, base || "/"));
  } else if (!user) {
    return { email };
  }

  /**
   * Handle logged in cases
   */
  if (!intent) {
    redirect(307, propagateSafeParams(url, `${base}/account/`));
  } else if (!isIntent(intent, globalIntents)) {
    redirect(307, propagateSafeParams(url, `${base}/account/`));
  } else if (intent === "checkout" && productId) {
    await purchaseIfNecessary(productId);
  } else if (intent === "checkout") {
    redirect(307, propagateSafeParams(url, resolveIntentRedirect(intent, productName)));
  } else if (isIntent(intent, redirectIntents)) {
    redirect(307, propagateSafeParams(url, resolveIntentRedirect(intent, productName)));
  }
};
